@import "src/app/styles/mixins";

.container {
  position: relative;
  transition: height 0.5s ease-out, padding 0.5s ease-out;
  flex: 1;
  background-color: #000000;
  max-width: 100vw;
  overflow: hidden;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: vw(6);
  padding: vw(12) vw(8) vw(6) vw(8);
}

.dietaries {
  display: flex;
  flex-direction: row;
  max-width: 30%;
}

.loader {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.card {
  height: vw(260);
  width: vw(176);
  flex-shrink: 0;
  border-radius: 16px;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: vw(16);
}

.cardContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: vw(8) vw(8);
  background: var(--small-dish-card, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.27%, rgba(0, 0, 0, 0.18) 16.68%, rgba(0, 0, 0, 0.38) 34.62%, rgba(0, 0, 0, 0.48) 55.24%, rgba(0, 0, 0, 0.50) 99.72%, rgba(0, 0, 0, 0.60) 99.72%));

}

.headerContainer {
  display: flex;
  align-items: center;
  gap: vw(6);
}

.headerText {
  color: #fff;
  font-size: vw(12);
  font-weight: 500;
  line-height: vw(16);
}

.containerBottom {
  display: flex;
  padding-top: vw(5);
  gap: vw(2);
  flex-wrap: wrap;
}

.controlBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: vw(6);
  margin-top: vw(8);
  padding-left: vw(8);
}

.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: vw(10);
  padding: vw(8) vw(12);
  border-radius: 999px;
  background-color: var(--dark-gray);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.70);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.overlayText {
  color: white;
  font-size: vw(20);
  text-align: center;
}
