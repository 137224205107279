@import "src/app/styles/mixins";


.container {
  display: flex;
}

.animatedContainer {
  position: relative;
  margin-bottom: vw(10);
  border-radius: 32px;
}

.restaurantCard {
  width: 100%;
  margin: vw(4) 0 vw(10) 0;
  -moz-border-radius-topleft: 32px;
  -moz-border-radius-topright: 32px;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: height 0.2s ease;
  position: relative;
  overflow: hidden;
  height: vw(600);

}

.videoWrapper {
  width: 100%;
  height: vw(450);
  position: absolute;
  border-radius: var(--avatar, 0px) var(--avatar, 0px) 32px 32px;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0.00) 0%, rgba(27, 27, 27, 0.90) 34.05%, #1B1B1B 41.39%, #1B1B1B 100%);
}

.gradient {
  position: absolute;
  top: vw(380);
  width: 100%;
  height: 100%;
  border-radius: var(--avatar, 0px) var(--avatar, 0px) 32px 32px;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0.00) 0%, #1B1B1B 7.20%, #1B1B1B 100%);
  z-index: 3;
}

.gradientOpenTime {
  position: absolute;
  top: vw(80);
  width: 100%;
  height: 100%;
  border-radius: var(--avatar, 0px) var(--avatar, 0px) 32px 32px;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0.00) 0%, #1B1B1B 45.20%, #1B1B1B 100%);
  z-index: 3;
}

.gradientBlack {
  background: linear-gradient(181.05deg, rgba(0, 0, 0, 0) -57.4%, #000000 99.1%);
  z-index: 30;
  width: 110%;
  height: vw(88);
  position: absolute;
  margin-left: -20px;
  animation: fadeIn 1s ease-in-out;;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.locationInfoBlock {
  z-index: 10;
}

.videoWrapper::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  border-radius: 0 0 32px 32px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerMenu {
  width: 100%;
  height: 100%;
  background-color: #1B1B1B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  padding-bottom: vw(57);
  padding-left: vw(16);
}

.headerTitle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: vw(8);
  z-index: 999;
  margin-top: vw(6);
  max-width: vw(300);
  //background-color: rgba(0, 0, 0, 0.5);
}

.additionalContent {
  margin-top: vw(10);
}

.buttonContainer {
  margin-top: -2px;
  display: flex;
  padding: vw(12);
  //box-shadow: black 0px 0px 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 50;
  gap: vw(5);
  margin: vw(3) vw(3) 0;

  .btn {
    z-index: 100;
    gap: vw(5);
    display: flex;
    align-items: center;
  }
}

.buttonContainerBtn {
  color: white;
  font-size: vw(15);
  line-height: 20;
  letter-spacing: -0.24;

}

.communicationButtons {
  display: flex;
  justify-content: start;
  width: 100%;
  gap: vw(8)
}

.time {
  display: flex;
  flex-direction: row;
  gap: vw(8);
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid white;
}


.touchableOpacity {
  display: inline-block;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}
