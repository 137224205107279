@import "src/app/styles/mixins";


.container {
  position: relative;
  width: 100%;
  height: vw(560);
  overflow: hidden;
  border-radius: 32px
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  z-index: -1;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 560px;
  object-fit: cover;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.40);
  filter: blur(20px);
}

.content {
  position: relative;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: vw(32)
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: vw(16);
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: vw(125);
}

.title {

  h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Schoolbook', sans-serif;
    font-size: vw(26);
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.28px;
  }
}

.blockedItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .blockedItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: vw(6);
    margin-bottom: vw(6);

    .blockedItemText {
      font-size: vw(16);
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.32px;
      color: var(--light-gray-color);
    }
  }
}

.buttonBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.button {
  display: flex;
  padding: vw(16) vw(32);
  justify-content: center;
  align-items: center;
  gap: vw(8);
  border-radius: 999px;
  background: #FFF;
  border: none;
  font-family: 'SF Pro Text', sans-serif;
  font-size: vw(17);
  font-weight: 600;
  color: var(--black-color);
}


