@import "src/app/styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  height: vw(290);
  padding: 0 vw(16) vw(24) vw(16);
  color: white;
  border-radius: 0px 0px 32px 32px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.27%, rgba(0, 0, 0, 0.50) 41.34%, rgba(0, 0, 0, 0.50) 99.72%, rgba(0, 0, 0, 0.50) 99.72%);
}
