@import "mixins";

:root {
  // Fonts
  --font-family-SFProText: 'SF Pro Text', sans-serif;
  --font-family-Schoolbook: 'Schoolbook', sans-serif;


  --font-size-l: vw(12);
  --fons-line-l: 16px;
  --font-l: var(--font-size-l)/var(--fons-line-l) var(--font-family-SFProText);

  --font-size-m: vw(15);
  --fons-line-m: 20px;
  --font-m: var(--font-size-m)/var(--fons-line-m) var(--font-family-SFProText);


  // Colors
  --light-gray-color: #9D9D9D;
  --dark-gray: #1B1B1B ;
  --black-color: #000;
  --white-color: #fff;


}
