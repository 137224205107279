@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.group {
  height: 44px;
  width: 44px;
}

.frame {
  height: 24px;
  width: 24px;
  top: 10px;
  left: 10px;
  animation: rotate 0.9s linear infinite;
}

.ellipse, .div, .ellipse2, .ellipse3 {
  background-color: #ffffff;
  border-radius: 4px;
  height: 8px;
  width: 8px;
  position: absolute;
}

.ellipse {
  top: 0;
  left: 0;
}

.div {
  left: 14px;
  top: 0;
}

.ellipse2 {
  left: 14px;
  top: 14px;
}

.ellipse3 {
  left: 0;
  top: 14px;
}
