@font-face {
  font-family: 'SF Pro Text';
  font-weight: 700;
  src: url('../../assets/fonts/SanFranciscoProText/SFProText-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 600;
  src: url('../../assets/fonts/SanFranciscoProText/SFProText-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 400;
  src: url('../../assets/fonts/SanFranciscoProText/SFProText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 500;
  src: url('../../assets/fonts/SanFranciscoProText/SFProText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Schoolbook';
  font-weight: 400;
  src: url('../../assets/fonts/schoolbook/Schoolbook-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Schoolbook';
  font-weight: 700;
  src: url('../../assets/fonts/schoolbook/schoolb.ttf') format('truetype');

}
