@import "variables";
@import "fonts";
@import "reset";
@import "mixins";

body {
  background-color: #000;
}


.app {
  max-width: 450px;
  width: 100%;
  margin: auto;
  font-size: var(--font-size-m);
  font-family: var(--font-family-SFProText);
  line-height: var(--fons-line-m);
}

.drawer{
  background-color: #FFFFFF;
}


